import { Glyph, Modal, Storage } from '@splotch/core-ui';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../store';
import { DeleteUploadButton } from './__styled';

export const RecentUploads = (): React.ReactElement | null => {
  const prompt = Modal.usePrompt();
  const { datumKey } = useContext(Store.Spectrum.Context);
  const [lastUpload, setLastUpload] = useState(
    Storage.Meta.normalize(JSON.parse(Storage.getItemMeta(datumKey!) ?? '{}'))
  );

  const deleteLastUpload = (): void => {
    prompt.confirm({
      title: 'Delete Uploaded Data?',
      content: 'Are you sure you want to delete your previously uploaded data?',
      icon: (
        <Glyph className='text-white bg-danger'>
          <i className='fas fa-exclamation' />
        </Glyph>
      ),
      okay: {
        label: 'Delete',
        variant: 'danger'
      },
      onAnswer: (answer: boolean): void => {
        if (answer) {
          Storage.removeItem(datumKey!);
          setLastUpload(undefined);
        }
      }
    });
  };

  return lastUpload?.uploadDate ? (
    <p className='text-left text-muted mt-3'>
      <i>Recently Uploaded:</i>{' '}
      <Link className='ml-2' to='/spectrum'>
        {DateTime.fromISO(lastUpload.uploadDate).toFormat('fff')}
      </Link>
      <DeleteUploadButton onClick={deleteLastUpload} />
    </p>
  ) : null;
};
