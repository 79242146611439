import { Theme } from '@splotch/core-ui';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { Footer } from '../../components';
import { SectionDivider, StyledHero } from './__styled';
import { GetInvolved } from './get-involved';
import { LandingDropzone } from './landing-dropzone';
import { SampleData } from './sample-data';

export const HomeRoute = (): React.ReactElement => {

  const viewport = Theme.useViewport();
  const viewportTooSmall = new Set(['xs', 'sm']);

  return (
    <>
      {viewportTooSmall.has(viewport) && (
        <Alert key='info' variant='info'>
          Splotch Labs is best viewed on a tablet or desktop.
        </Alert>
        )
      }
      <StyledHero fluid className='d-flex flex-column align-items-center justify-content-center'>
        <div className='img-container'>
          <div className='hero-content'>
            <div>
              <h1 className='font-weight-bold'>
                Explore NMR spectra with <br />
                <i>Splotch Labs</i>
              </h1>
              <p className='mt-3'>
                We&apos;re on a mission to provide convenient access to tools that help streamline
                scientific research, starting with anaylitical techniques in chemistry.
              </p>
              {!viewportTooSmall.has(viewport) && <LandingDropzone />}
            </div>
          </div>
        </div>
      </StyledHero>
      <SectionDivider />
      <SampleData />
      <SectionDivider />
      <GetInvolved />
      <Footer />
    </>
  );
};
