import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@splotch/core-ui';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import type { PartialDeep } from 'type-fest';
import { FileDropzone } from '../../components';
import type { Spectrum1D } from '../../model';
import { Store } from '../../store';
import { FileUtils, Loaders, Spectra } from '../../utils';
import { RecentUploads } from './recent-uploads';

export const LandingDropzone = (): React.ReactElement => {
  const history = useHistory();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addSpectra } = Store.Spectrum.useAddSpectra();

  const onDrop = async (files: File[]): Promise<void> => {
    setLoading(true);

    const uniqueFileExtensions = [
      ...new Set(files.map((file) => FileUtils.getFileExtension(file.name)))
    ];

    await Promise.all(
      uniqueFileExtensions.map(
        async (extension): Promise<void> => {
          const selectedFilesByExtensions = files.filter(
            (file) => FileUtils.getFileExtension(file.name) === extension
          );

          let loadedFiles: FileUtils.LoadedFile[];
          let spectra: Spectrum1D.Datum[] = [];

          switch (extension) {
            case FileUtils.NmrFileTypes.ZIP:
              loadedFiles = await Loaders.loadZip({ files: selectedFilesByExtensions });
              spectra = (
                await Promise.all(
                  loadedFiles.map(async ({ name, binary, isFid }) => {
                    const options: PartialDeep<Spectrum1D.Datum> = { display: { name } };

                    if (isFid) {
                      options.info = {
                        isFid: true
                      };
                      options.originalInfo = {
                        isFid: true
                      };
                    }

                    return Spectra.Handlers.bruker(options, binary, {
                      '1d': [],
                      '2d': []
                    });
                  })
                )
              ).flat();
              break;

            case FileUtils.NmrFileTypes.DX:
            case FileUtils.NmrFileTypes.JDX:
              loadedFiles = await Loaders.loadDX({ files: selectedFilesByExtensions });
              spectra = Spectra.Handlers.jcamp(loadedFiles, {});
              break;

            default:
              break;
          }

          addSpectra(spectra);
          setLoading(false);
          history.push('/spectrum');
        }
      )
    );
  };

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div className='text-center'>
      <FileDropzone
        onDrop={onDrop}
        activeText='Drop your file here ...'
        inactiveText='Drop raw data here, or click to select file'
      />
      <p className='text-left'>
        <small>
          <em>NOTE:</em> If you&apos;re used to uploading folders here, things have changed. We now
          support uploading archives (ZIPs) or JCAMP files.{' '}
        </small>
      </p>
      <RecentUploads />
      <Modal
        show={showErrorModal}
        centered
        onHide={(): void => {
          setShowErrorModal(false);
        }}
      >
        <Modal.Body className='text-center'>
          <div className='text-center'>
            <FontAwesomeIcon className='text-danger' icon={faExclamationCircle} size='2x' />
          </div>
          <h2 className='text-center pt-2'>Oops!</h2>
          <p className='text-center p-3'>
            Something unexpected happened while your upload was processing, please try again and if
            the error persists, feel free to submit a <Link to='/bug-report'>bug report</Link>.
          </p>
          <Button
            variant='outline-secondary'
            onClick={(): void => {
              setShowErrorModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
