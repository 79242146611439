import type { Any } from '@splotch/core-utils';
import airPLS from 'ml-airpls';
import equallySpaced from 'ml-array-xy-equally-spaced';
import baselineRegression from 'ml-baseline-correction-regression';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace BaselineCorrection {
  export const id = 'BaselineCorrection';
  export const name = 'Baseline correction';

  export const baselineAlgorithms = {
    airpls: 'airPLS',
    polynomial: 'Polynomial'
  };

  export const isApplicable = (datum: Spectrum1D.Datum): boolean => {
    if (!datum.info.isFid) {
      return true;
    }

    return false;
  };

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  interface Options {
    algorithm?: string;
    zones?: Any[];
    maxIterations?: number;
    factorCriterion?: number;
    weights?: number[];
    lambda?: number;
    controlPoints?: number[];
    baseLineZones?: number[];
    degree?: Any;
  }

  export const apply = (datum: Spectrum1D.Datum, options: Options = {}): void => {
    if (!isApplicable(datum)) {
      throw new Error('baselineCorrection not applicable on this data');
    }
    const { algorithm, zones = [] } = options;
    const { x, re } = datum.data;

    let corrected;

    switch (algorithm) {
      case 'airpls':
        // eslint-disable-next-line prefer-destructuring
        corrected = airPLS([...x], [...re], options).corrected;
        break;
      case 'polynomial':
        {
          const baselineOptions = {
            // regression: polynomialRegression,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            regressionOptions: options.degree
          };
          const reduced = equallySpaced({ x, y: re }, { numberOfPoints: 4096, zones });
          const result = baselineRegression(reduced.x, reduced.y, baselineOptions);
          const { regression } = result;

          corrected = new Float64Array(x.length);
          for (const [i, element] of re.entries()) {
            corrected[i] = element - regression.predict(x[i]);
          }
        }

        break;
      default:
        throw new Error(`BaselineCorrection: algorithm unknown: ${algorithm ?? '<empty>'}`);
    }

    Object.assign(datum.data, { re: corrected });
  };
}
