import React, { memo, useMemo } from 'react';
import type { ElementType, PropsWithChildren, ReactElement } from 'react';
import { isElement } from 'react-is';
import { useImmer } from 'use-immer';
import { Context } from './context';

const Wrapper = memo(
  // eslint-disable-next-line react/prop-types
  ({ el }: { el: ElementType | ReactElement }): ReactElement =>
    isElement(el) ? el : React.createElement(el)
);

Wrapper.displayName = 'Wrapper<Prompt>';

export const Provider = ({ children, ...rest }: Provider.Props): ReactElement => {
  const [prompts, setPrompts] = useImmer(new Map<string, ElementType | ReactElement>());

  const value = useMemo(
    () => ({
      addPrompt(key: string, el: ElementType | ReactElement): void {
        setPrompts((draft): void => {
          draft.set(key, el);
        });
      },

      removePrompt(key: string): void {
        setPrompts((draft): void => {
          draft.delete(key);
        });
      }
    }),
    [setPrompts]
  );

  return (
    <Context.Provider value={value} {...rest}>
      {children}

      {[...prompts.entries()].map(
        ([key, el]): ReactElement => (
          <Wrapper key={key} el={el} />
        )
      )}
    </Context.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Provider {
  export type Props = PropsWithChildren<unknown>;
}
