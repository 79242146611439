import { faEllipsisH, faMousePointer, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@splotch/core-ui';
import React, { Fragment, useCallback, useContext } from 'react';
import {
  Accordion,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover,
  Tooltip
} from 'react-bootstrap';
import { CUSTOM_MULTIPLICITY, MULTIPLICITIES, PEAK_PICKING_STAGES } from '../../../../constants';
import { getFloategrals, rationalize } from '../../../../utils';
import { FixedWidthPopover } from '../../_.styled';
import { SpectrumContext } from '../../spectrum-store';

export const PeakPicking = (): React.ReactElement => {
  const [
    { correctedData, peaks, peakPickingStatus },
    { setPeaks, updatePeakPickingStatus }
  ] = useContext(SpectrumContext);

  const onChangeMultiplicity = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
      idx: number
    ): void => {
      let multiplicity: string = e.target.value;

      const customMultiplicity = multiplicity === CUSTOM_MULTIPLICITY;

      multiplicity = customMultiplicity ? '' : multiplicity;

      const newPeaks = [...peaks];

      newPeaks[idx] = { ...newPeaks[idx], multiplicity, customMultiplicity };
      setPeaks!(newPeaks);
    },
    [setPeaks, peaks]
  );

  const onChangeCustomMultiplicity = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
      idx: number
    ): void => {
      const multiplicity = e.target.value;

      const newPeaks = [...peaks];

      newPeaks[idx] = { ...newPeaks[idx], multiplicity };
      setPeaks!(newPeaks);
    },
    [setPeaks, peaks]
  );

  const onRemoveCustomMultiplicity = useCallback(
    (idx: number) => {
      const newPeaks = [...peaks];

      newPeaks[idx] = { ...newPeaks[idx], multiplicity: undefined, customMultiplicity: undefined };
      setPeaks!(newPeaks);
    },
    [setPeaks, peaks]
  );

  const onDeletePeak = useCallback(
    (index: number) => {
      const newPeaks = [...peaks.slice(0, index), ...peaks.slice(index + 1)];
      const ranges = newPeaks.map((i) => i.range);
      const floategrals = getFloategrals(correctedData, ranges);
      const rationals = rationalize(floategrals);

      newPeaks.forEach((peak, j) => {
        peak.area = rationals[j];
      });
      setPeaks!(newPeaks);
    },
    [correctedData, peaks, setPeaks]
  );

  return (
    <Accordion defaultActiveKey='0'>
      <Popover.Title className='d-flex justify-content-between align-items-center'>
        <span className='mr-5'>Picking Peaks</span>
        <span>
          <Accordion.Toggle as={Button} size='sm' eventKey='0'>
            Show / Hide
          </Accordion.Toggle>
          {' '}
          <Button
            className='ml-2'
            variant='success'
            size='sm'
            onClick={(): void => {
              updatePeakPickingStatus!({ state: PEAK_PICKING_STAGES.INACTIVE });
            }}
          >
            Done
          </Button>
        </span>
      </Popover.Title>
      <Accordion.Collapse eventKey='0'>
        <FixedWidthPopover width='45rem'>
          <Grid
            gridTemplateColumns='5rem 11rem 7rem 10rem 4rem 2rem'
            gap='0.5rem'
            justifyItems='center'
          >
            <p className='text-center font-weight-bold'>Chemical Shift</p>
            <p className='text-center font-weight-bold'>Peak Range</p>
            <p className='text-center font-weight-bold'>Multiplicity</p>
            <p className='text-center font-weight-bold'>Coupling Factor (Δppm)</p>
            <p className='text-center font-weight-bold'>
              <sup>1</sup>H Integration
            </p>
            <div />
            {peaks.length ? (
              peaks.map((peak, index) => (
                <Fragment key={peak.id}>
                  <Form.Control
                    as='input'
                    type='text'
                    defaultValue={peak.center}
                    onChange={(e): void => {
                      const value = Number.parseFloat(e.target.value);

                      if (!Number.isNaN(value)) {
                        const updatedPeaks = [...peaks];

                        updatedPeaks[index].center = value;
                        setPeaks!(updatedPeaks);
                        if (peakPickingStatus.state !== PEAK_PICKING_STAGES.LEFT_LIMIT) {
                          updatePeakPickingStatus!({
                            state: PEAK_PICKING_STAGES.LEFT_LIMIT
                          });
                        }
                      }
                    }}
                  />
                  <Grid
                    gridTemplateColumns='5rem 1rem 5rem'
                    justifyItems='center'
                    alignItems='center'
                  >
                    <Form.Control
                      as='input'
                      type='text'
                      defaultValue={peak.range.xMin.toFixed(4)}
                      onChange={(e): void => {
                        const value = Number.parseFloat(e.target.value);

                        if (!Number.isNaN(value)) {
                          const updatedPeaks = [...peaks];

                          updatedPeaks[index].range.xMin = value;
                          setPeaks!(updatedPeaks);
                        }
                      }}
                    />
                    <FontAwesomeIcon icon={faEllipsisH} size='xs' />
                    <Form.Control
                      as='input'
                      type='text'
                      defaultValue={peak.range.xMax.toFixed(4)}
                      onChange={(e): void => {
                        const value = Number.parseFloat(e.target.value);

                        if (!Number.isNaN(value)) {
                          const updatedPeaks = [...peaks];

                          updatedPeaks[index].range.xMax = value;
                          setPeaks!(updatedPeaks);
                        }
                      }}
                    />
                  </Grid>
                  {peak.customMultiplicity ? (
                    <Grid gridTemplateColumns='5rem 2rem' justifyItems='center'>
                      <Form.Control
                        onChange={(e): void => {
                          onChangeCustomMultiplicity(e, index);
                        }}
                        defaultValue={peak.multiplicity ?? ''}
                      />
                      <Button
                        variant='link'
                        className='text-danger'
                        style={{ borderRadius: '100%', width: 'fit-content' }}
                        onClick={(): void => {
                          onRemoveCustomMultiplicity(index);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Grid>
                  ) : (
                    <Form.Control
                      as='select'
                      onChange={(e): void => {
                        onChangeMultiplicity(e, index);
                      }}
                      defaultValue={peak.multiplicity ?? 'Select...'}
                      custom
                    >
                      <option disabled>Select...</option>
                      {MULTIPLICITIES.map((option) => (
                        <option value={option.code} key={option.code}>
                          {option.label} ({option.code})
                        </option>
                      ))}
                      <option value={CUSTOM_MULTIPLICITY}>Other...</option>
                    </Form.Control>
                  )}
                  <InputGroup>
                    <OverlayTrigger
                      trigger='click'
                      placement='bottom'
                      rootClose
                      overlay={
                        <Tooltip id='Coupling-Input-Tooltip'>
                          Type coupling factor(s) as a comma separated list (i.e &quot;2.3,4.0&quot;
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        as='input'
                        type='text'
                        autoComplete='new-off'
                        value={peak.couplingFactors}
                        onChange={(e): void => {
                          const { value } = e.target;
                          const updatedPeaks = [...peaks];

                          updatedPeaks[index].couplingFactors = value;
                          setPeaks!(updatedPeaks);
                        }}
                      />
                    </OverlayTrigger>
                    <InputGroup.Append>
                      {peakPickingStatus.center === peak.center &&
                      (peakPickingStatus.state === PEAK_PICKING_STAGES.FIRST_COUPLING_PEAK ||
                        peakPickingStatus.state === PEAK_PICKING_STAGES.SECOND_COUPLING_PEAK) ? (
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id='Coupling-Selection-Tooltip'>Cancel Selection</Tooltip>
                          }
                        >
                          <Button
                            variant='outline-danger'
                            onClick={(): void => {
                              updatePeakPickingStatus!({
                                state: PEAK_PICKING_STAGES.LEFT_LIMIT
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id='Coupling-Selection-Tooltip'>
                              Select Peaks To Add A Coupling Factor
                            </Tooltip>
                          }
                        >
                          <Button
                            variant='secondary'
                            onClick={(): void => {
                              updatePeakPickingStatus!({
                                state: PEAK_PICKING_STAGES.FIRST_COUPLING_PEAK,
                                center: peak.center
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faMousePointer} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control as='input' type='text' value={peak.area?.toFixed(2)} disabled />
                  <Button
                    variant='link'
                    className='text-danger'
                    style={{ borderRadius: '100%', width: 'fit-content' }}
                    onClick={(): void => {
                      onDeletePeak(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Fragment>
              ))
            ) : (
              <>
                <p className='text-center'>-</p>
                <p className='text-center'>-</p>
                <p className='text-center'>-</p>
                <p className='text-center'>-</p>
                <p className='text-center'>-</p>
              </>
            )}
          </Grid>
        </FixedWidthPopover>
      </Accordion.Collapse>
    </Accordion>
  );
};
