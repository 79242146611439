import type { Any } from '@splotch/core-utils';
import { Nix, Obj, Str } from '@splotch/core-utils';

export namespace Storage {
  const META_POSTFIX = '_meta';

  export interface Options {
    persistent?: boolean;
  }

  export interface Meta {
    uploadDate: string;
  }

  export namespace Meta {
    export const normalize = (value: Record<string, unknown>): Meta | undefined => {
      const uploadDate = Str.normalize(value.uploadDate);

      return Obj.normalize<Meta>({ uploadDate });
    };
  }

  export const getItem = (key: string): string | undefined => {
    const v = sessionStorage.getItem(key) ?? localStorage.getItem(key);

    return Nix.isNotNil(v) ? v : undefined;
  };

  export const getItemMeta = (key: string): string | undefined => {
    const metaKey = `${key}${META_POSTFIX}`;

    const v = sessionStorage.getItem(metaKey) ?? localStorage.getItem(metaKey);

    return Nix.isNotNil(v) ? v : undefined;
  };

  export const removeItem = (key: string): void => {
    const metaKey = `${key}${META_POSTFIX}`;

    sessionStorage.removeItem(key);
    localStorage.removeItem(key);

    if (sessionStorage.getItem(metaKey)) {
      sessionStorage.removeItem(metaKey);
    }

    if (localStorage.getItem(metaKey)) {
      localStorage.removeItem(metaKey);
    }
  };

  export const setItem = (
    key: string,
    value: string,
    options: Options = { persistent: true }
  ): void => {
    const storage = options.persistent ? localStorage : sessionStorage;

    const meta = {
      uploadDate: new Date()
    };

    storage.setItem(key, value);
    storage.setItem(`${key}${META_POSTFIX}`, JSON.stringify(meta));
  };

  export const replacer = (_key: string, value: Any): Any => {
    if (value instanceof Float64Array) {
      return [...value];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value;
  };
}
