import { xParetoNormalization } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace Pareto {
  export const id = 'Pareto';
  export const name = 'Pareto';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  export const apply = (datum: Spectrum1D.Datum): void => {
    if (!isApplicable(datum)) {
      throw new Error('Pareto is not applicable on this data');
    }

    const re = [...datum.data.re];

    datum.data.re = Float64Array.from(xParetoNormalization(re));

    if (datum.data.im) {
      const im = [...datum.data.im];

      datum.data.im = Float64Array.from(xParetoNormalization(im));
    }
  };
}
