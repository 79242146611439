import _ from 'lodash';
import React from 'react';
import type { BsPrefixProps } from 'react-bootstrap/helpers';
import ModalTitle from 'react-bootstrap/ModalTitle';

export const Title = React.forwardRef(
  ({ children, ...props }: Title.Props, ref: React.Ref<HTMLDivElement>): React.ReactElement => (
    <ModalTitle
      {..._.defaults(props, {
        as: 'h1'
      })}
      ref={ref}
    >
      {children}
    </ModalTitle>
  )
);

Title.displayName = 'WrappedModalTitle';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Title {
  export type Props = BsPrefixProps<'div'> & React.ComponentPropsWithRef<'div'>;
}
