import { Viewport } from './viewport';

export enum Device {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKSTOP = 'desktop'
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Device {
  /* eslint-disable @typescript-eslint/no-unnecessary-qualifier */
  export const from = (v: Viewport): Device =>
    v === Viewport.XS || v === Viewport.SM ? Device.MOBILE : v === Viewport.MD ? Device.TABLET : Device.DESKSTOP;

  export const isMobile = (v: Viewport): boolean => from(v) === Device.MOBILE;
  export const isTablet = (v: Viewport): boolean => from(v) === Device.TABLET;
  export const isDesktop = (v: Viewport): boolean => from(v) === Device.DESKSTOP;
}
