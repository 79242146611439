import { reimFFT, reimPhaseCorrection } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';
import { DigitalFilter } from './digital-filter';

export namespace FFT {
  export const id = 'FFT';
  export const name = 'FFT';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !!datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: true
  });

  const generateXAxis = (datum: Spectrum1D.Datum): Float64Array => {
    const { info } = datum;

    const { baseFrequency, frequencyOffset, spectralWidth } = info;
    const offset = frequencyOffset! / baseFrequency!;
    const spectralHalfWidth = 0.5 * spectralWidth!;
    const nbPoints = datum.data.x.length;
    let firstPoint = offset - spectralHalfWidth;
    const dx = spectralWidth! / (nbPoints - 1);
    const xAxis = [];

    for (let i = 0; i < nbPoints; i++) {
      xAxis[i] = firstPoint;
      firstPoint += dx;
    }

    return Float64Array.from(xAxis);
  };

  export const apply = (datum: Spectrum1D.Datum): void => {
    if (!isApplicable(datum)) {
      throw new Error('fft not applicable on this data');
    }

    const digitalFilterApplied = datum.filters.some((e) => e.name === DigitalFilter.name && e.flag);

    Object.assign(datum.data, reimFFT(datum.data, { applyZeroShift: true }));

    const { digitalFilter } = datum.info;

    if (digitalFilterApplied && digitalFilter) {
      const ph1 = (digitalFilter - Math.floor(digitalFilter)) * Math.PI * 2;

      Object.assign(datum.data, reimPhaseCorrection(datum.data, 0, ph1));
    }

    datum.data.x = generateXAxis(datum);
    datum.info = { ...datum.info, isFid: false };
  };
}
