import type { JSZipObject } from 'jszip';

export namespace FileUtils {
  export enum NmrFileTypes {
    MOL = 'mol',
    NMRIUM = 'nmrium',
    JSON = 'json',
    DX = 'dx',
    JDX = 'jdx',
    JDF = 'jdf',
    ZIP = 'zip',
    NMREDATA = 'nmredata'
  }

  export const getFileExtension = (name: string): string => name.replace(/^.*\./, '').toLowerCase();

  export const getFileName = (name: string): string =>
    name.slice(0, Math.max(0, name.lastIndexOf('.')));

  export interface LoadedFile {
    binary: ArrayBuffer | string;
    name: string;
    extension: string;
    jcampURL?: string;
    isFid?: boolean;
  }

  // @fixme: Add retries on async failures
  export const loadFiles = async (
    acceptedFiles: File[],
    { asBuffer = false }
  ): Promise<LoadedFile[]> =>
    Promise.all(
      acceptedFiles.map(
        async (file): Promise<{ binary: ArrayBuffer | string; name: string; extension: string }> =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener('abort', reject);
            reader.addEventListener('error', reject);
            reader.addEventListener('load', () => {
              if (reader.result) {
                resolve({
                  binary: reader.result,
                  name: getFileName(file.name),
                  extension: getFileExtension(file.name)
                });
              }
            });

            if (asBuffer) {
              reader.readAsArrayBuffer(file);
            } else {
              reader.readAsBinaryString(file);
            }
          })
      )
    );

  // @fixme: Add retries on async failures
  export const loadFilesFromZip = async (
    files: JSZipObject[],
    { asBuffer = false }
  ): Promise<{ binary: Uint8Array | string; name: string; extension: string }[]> =>
    Promise.all(
      files.map(async (file) => {
        const binary = await file.async(asBuffer ? 'uint8array' : 'text');
        const name = getFileName(file.name);
        const extension = getFileExtension(file.name);

        return { binary, name, extension };
      })
    );
}
