import React from 'react';
import { Navbar as _Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Icons } from '../svg';
import { StyledNavbar } from './__styled';

export const Navbar = (): React.ReactElement => {
  const location = useLocation();
  const useSpectrumStyles = location.pathname === '/spectrum';

  return (
    <StyledNavbar expand='sm' useSpectrumStyles={useSpectrumStyles} className='bg-white'>
      <div className='nav-fixed-width d-flex'>
        <_Navbar.Brand as={Link} className='pb-0' to='/'>
          <span style={{ fontSize: '24px' }}>
            SPL
            <Icons.MethaneLogo style={{ margin: '-5px -13px 0px' }} />
            TCH
          </span>
        </_Navbar.Brand>
        <_Navbar.Toggle aria-controls='basic-navbar-nav' />
        <_Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link as={Link} to='/'>
              Home
            </Nav.Link>
            <NavDropdown className='nav-bar-item' title='Learning' id='basic-nav-dropdown'>
              <NavDropdown.Item as={Link} to='/resources'>
                Resources
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/tutorial'>
                Tutorial
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to='/faq'>
                FAQ
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Contact' id='basic-nav-dropdown'>
              <NavDropdown.Item as={Link} to='/feature-request'>
                Feature Request
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/bug-report'>
                Report a Bug
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/contact'>
                Contact Us
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </_Navbar.Collapse>
      </div>
    </StyledNavbar>
  );
};
