import type { Theme } from '@splotch/core-ui';
import { Grid } from '@splotch/core-ui';
import Color from 'color';
import { Card, CloseButton, Container } from 'react-bootstrap';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

export const StyledHero = styled(Container)`
  background-color: ${({ theme }: { theme: Theme }): string =>
    Color(theme.colors.secondary).lighten(0.7).alpha(0.6).toString()};
  padding: 4rem;

  .img-container {
    width: var(--max-page-width);
    background-image: url('/assets/splotch-labs.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 100%;
    text-align: left;

    .hero-content {
      padding-top: 64px;
      min-height: 33rem;
      background-color: ${({ theme }: { theme: Theme }): string =>
        Color(theme.colors.secondary).lighten(0.7).alpha(0.6).toString()};
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 64px;
  ${({ theme }: { theme: Theme }): SimpleInterpolation => css`
    background-color: ${theme.colors.secondary.hex()};
    border-top: 3px solid ${theme.colors.dark.hex()};
    border-bottom: 3px solid ${theme.colors.dark.hex()};
  `}
`;

export const DeleteUploadButton = styled(CloseButton)`
  margin-top: -4px;
`;

export const SampleDataContainer = styled.div`
  background-color: ${({ theme }: { theme: Theme }): string =>
    Color(theme.colors.secondary).lighten(0.7).alpha(0.25).toString()};
  .sample-data-content {
    width: var(--max-page-width);
    margin: 0 auto;
  }
`;

export const ClickableSpan = styled.span`
  cursor: pointer;
`;

export const SampleDataTable = styled(Grid)`
  padding-bottom: 32px;
  justify-items: center;
`;

export const StyledCard = styled(Card)`
  width: calc(var(--max-page-width) / 4 - 2rem);
  ${({ theme }: { theme: Theme }): SimpleInterpolation => css`
    border: 2px solid ${Color(theme.colors.primary).alpha(0.25).toString()};
    :hover {
      border-color: var(--primary);
    }
  `}
  img {
    padding: 1rem;
    height: 200px;
  }
`;