import { Loading } from '@splotch/core-ui';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Store } from '../../store';
import { SpectrumRoute } from './spectrum';
import { SpectrumProvider } from './spectrum-store';

export const SpectrumWrapper = (): React.ReactElement => {
  const history = useHistory();
  const { datum, datumKey } = useContext(Store.Spectrum.Context);

  // Context has loaded, but datum is undefined
  if (!datum && datumKey) {
    history.push('/');
  }

  return datum ? (
    <SpectrumProvider>
      <SpectrumRoute />
    </SpectrumProvider>
  ) : (
    <Loading />
  );
};
