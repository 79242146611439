import type { Theme } from '@splotch/core-ui';
import Color from 'color';
import { Col, Modal, Popover } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSidebar = styled.div`
  border-right: 1px solid lightgray;
  background-color: ${({ theme }: { theme: Theme }): string =>
    Color(theme.colors.secondary).alpha(0.1).toString()};
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
  width: 4rem;

  .sidebar-button {
    display: block;
    width: 100%;
    height: 4rem;
    padding: 0;
    text-align: center;
    border: none;
    border-radius: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    font-size: 1.25em;

    &:hover {
      background-color: transparent;
      border-right: 5px solid var(--secondary);
    }

    &:focus {
      box-shadow: none;
    }

    &.open {
      border-right: 5px solid var(--secondary);
    }

    svg {
      color: var(--tertiary);
    }
  }
`;

export const HelpModalWrapper = styled(Modal)`
  .modal-dialog {
    width: 40%;
    max-width: none !important;
  }
`;

export const AutoSaveIconWrapper = styled(Col)`
  display: flex;
  align-items: center;
`;

export const AutoSaveIcon = styled.div`
  opacity: 1;
  transition: all 0.2s ease-in-out;

  ::before {
    content: '✔︎';
    color: #008000;
  }
`;

export const FixedWidthPopover = styled(Popover.Content)`
  width: ${({ width }: { width?: string }): string => width ?? '35rem'};
`;
