import type { Any } from '@splotch/core-utils';
import { Arr, Obj } from '@splotch/core-utils';
import type { FileUtils } from '../utils';
import type { Filter } from './filter';

export namespace Spectrum1D {
  export interface Data {
    im?: Float64Array;
    re: Float64Array;
    x: Float64Array;
  }

  export interface Display {
    color: string;
    isPeaksMarkersVisible: boolean;
    isRealSpectrumVisible: boolean;
    isVisible: boolean;
    isVisibleInDomain: boolean;
    name: string;
  }

  export interface Info {
    baseFrequency: number;
    digitalFilter: number;
    dimension: number;
    frequencyOffset: number;
    isComplex: boolean;
    isFid: boolean;
    isFt: boolean;
    nucleus: string[] | string;
    spectralWidth: number;
  }

  export interface Peak {
    delta: number;
    id: string;
    originDelta: number;
    width: number;
  }

  export interface Peaks {
    options: Any;
    values: Partial<Peak>[];
  }

  export interface Integral {
    absolute: number;
    from: number;
    id: string;
    integral: number;
    kind: string;
    originFrom: number;
    originTo: number;
    to: number;
  }
  export interface Integrals {
    options: Partial<{ sum: number }>;
    values: Partial<Integral>[];
  }

  export interface Signal {
    delta: number;
    id: string;
    kind: string;
    multiplicity: string;
    originDelta?: number;
    peak?: Partial<{ x: number; intensity: number; width: number }>[];
  }
  export interface Range {
    absolute: number;
    from: number;
    id: string;
    integral: number;
    kind: string;
    originFrom?: number;
    originTo?: number;
    signal?: Partial<Signal>[];
    to: number;
  }

  export interface Ranges {
    options: Partial<{ sum: number }>;
    values: Partial<Range>[];
  }

  export interface Source {
    file: FileUtils.LoadedFile;
    jcampURL?: string;
  }

  export interface Datum {
    data: Data;
    display: Partial<Display>;
    filters: Filter[];
    id: number | string;
    info: Partial<Info>;
    integrals: Integrals;
    meta: Record<string, unknown>;
    originalData?: Data;
    originalInfo?: Partial<Info>;
    peaks: Peaks;
    ranges: Ranges;
    shiftX: number;
    source?: Partial<Source>;
  }

  export namespace Datum {
    interface Candidate {
      data?: {
        x?: number[];
        re?: number[];
        im?: number[];
      };
    }
    // @fixme: this should do a better job normalizing the value
    export const normalize = (value: unknown): Datum | undefined => {
      const candidate = Obj.normalize<Candidate>(value);

      return Obj.normalize<Datum>({
        ...candidate,
        data: {
          x: Float64Array.from(Arr.normalize(candidate?.data?.x)),
          re: Float64Array.from(Arr.normalize(candidate?.data?.re)),
          im: Float64Array.from(Arr.normalize(candidate?.data?.im))
        }
      });
    };
  }
}
