import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace DigitalFilter {
  export const id = 'DigitalFilter';
  export const name = 'Digital Filter';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !!datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: true
  });

  interface Options {
    digitalFilterValue?: number;
  }

  export const apply = (datum: Spectrum1D.Datum, options: Options = {}): void => {
    if (!isApplicable(datum)) {
      throw new Error('Digital Filter is not applicable on this data');
    }

    const { digitalFilterValue = 0 } = options;
    const re = new Float64Array(datum.data.re);

    let pointsToShift = Math.floor(digitalFilterValue);

    const skip = 0;

    pointsToShift += 0;

    const newRe = new Float64Array(re.length);

    newRe.set(re.slice(pointsToShift));
    newRe.set(re.slice(skip, pointsToShift), re.length - pointsToShift);

    datum.data.re = newRe;

    if (datum.data.im) {
      const im = new Float64Array(datum.data.im);
      const newIm = new Float64Array(im.length);

      newIm.set(im.slice(pointsToShift));
      newIm.set(im.slice(skip, pointsToShift), im.length - pointsToShift);
      datum.data.im = newIm;
    }
  };
}
