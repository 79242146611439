import { mean } from 'ml-stat/array';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace CenterMean {
  export const id = 'CenterMean';
  export const name = 'Center Mean';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  export const apply = (datum: Spectrum1D.Datum): void => {
    if (!isApplicable(datum)) {
      throw new Error('Center Mean is not applicable on this data');
    }

    const { im, re } = datum.data;

    const reMean = mean(re);
    const imMean = im ? mean(im) : undefined;

    for (let i = 0; i < re.length; i++) {
      re[i] -= reMean;

      if (im && imMean) {
        im[i] -= imMean;
      }
    }

    datum.data.re = re;
    datum.data.im = im;
  };
}
