import { reimAbsolute } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace Absolute {
  export const id = 'Absolute';
  export const name = 'Absolute';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean => {
    if (datum.info.isComplex && !datum.info.isFid) {
      return true;
    }

    return false;
  };

  export const reduce = (): Filter.ReduceResult => ({
    once: false
  });

  export const apply = (datum: Spectrum1D.Datum): void => {
    if (!isApplicable(datum)) {
      throw new Error('absolute not applicable on this data');
    }

    datum.data.re = reimAbsolute({
      re: datum.data.re,
      im: datum.data.im!
    });
    datum.data.im = new Float64Array(0);
    datum.info = { ...datum.info, isComplex: false };
  };
}
