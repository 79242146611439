import { Modal } from '@splotch/core-ui';
import React from 'react';
import type { ReactElement } from 'react';
import { Store } from './store';
import { Provider as ThemeProvider } from './theme';

export const Providers = ({ children, ...rest }: Providers.Props): ReactElement => (
  <ThemeProvider {...rest}>
    <Modal.Prompt.Provider {...rest}>
      <Store.Spectrum.Provider>{children}</Store.Spectrum.Provider>
    </Modal.Prompt.Provider>
  </ThemeProvider>
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Providers {
  export type Props = React.PropsWithChildren<ThemeProvider.Props>;
}
