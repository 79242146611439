import { Storage } from '@splotch/core-ui';
import React, { useMemo, useState } from 'react';
import { Spectrum1D } from '../../model';
import type { Store } from './context';
import { Context } from './context';

const DATUM_KEY = '__splotch_upload';

export const Provider = ({ children }: React.PropsWithChildren<unknown>): React.ReactElement => {
  const storedDatum = Storage.getItem(DATUM_KEY);
  const _datum = Spectrum1D.Datum.normalize(storedDatum ? JSON.parse(storedDatum) : undefined);

  const [datum, setDatum] = useState<Spectrum1D.Datum | undefined>(_datum);

  const value = useMemo((): Store => ({ datum, datumKey: DATUM_KEY, setDatum }), [datum, setDatum]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
