import { assert } from '@sindresorhus/is';
import type { Chart } from 'highcharts';
import React, { createContext, createRef, useCallback, useContext, useMemo, useState } from 'react';
import { PEAK_PICKING_STAGES } from '../../constants';
import { Store } from '../../store';
import { useDebounce } from '../../utils';

interface DataStore {
  zeroOrderPhaseCorrection: number;
  firstOrderPhaseCorrection: number;
  peaks: {
    id: string;
    center: number;
    range: {
      xMin: number;
      xMax: number;
    };
    area?: number;
    couplingFactors?: string;
    multiplicity?: string;
    customMultiplicity?: boolean;
  }[];
  correctedData: {
    x: number;
    y: number;
  }[];
  peakPickingStatus: {
    state: PEAK_PICKING_STAGES;
    leftLimit?: number;
    firstCouplingPeak?: number;
    center?: number;
  };
  chartRef?: React.RefObject<{ chart: Chart; container: React.RefObject<HTMLDivElement> }>;
}

interface ActionStore {
  setZeroOrderPhaseCorrection?: React.Dispatch<React.SetStateAction<number>>;
  setFirstOrderPhaseCorrection?: React.Dispatch<React.SetStateAction<number>>;
  setPeaks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        center: number;
        range: {
          xMin: number;
          xMax: number;
        };
        area?: number;
        couplingFactors?: string;
        multiplicity?: string;
        customMultiplicity?: boolean;
      }[]
    >
  >;
  updatePeakPickingStatus?: React.Dispatch<
    React.SetStateAction<{
      state: PEAK_PICKING_STAGES;
      leftLimit?: number;
      firstCouplingPeak?: number;
      center?: number;
    }>
  >;
  updateDataWindow?: ({ min, max }: { min: number; max: number }) => void;
}

export const SpectrumContext = createContext<[DataStore, ActionStore]>([
  {
    zeroOrderPhaseCorrection: 0,
    firstOrderPhaseCorrection: 0,
    peaks: [],
    correctedData: [],
    peakPickingStatus: {
      state: PEAK_PICKING_STAGES.INACTIVE
    }
  },
  {}
]);

const Y_SCALE_VALUE = 15.0;
const SLIDER_DEBOUNCE_DELAY = 5;

export const SpectrumProvider = (props: { children: React.ReactNode }): React.ReactElement => {
  const [zeroOrderPhaseCorrection, setZeroOrderPhaseCorrection] = useState(0);
  const [firstOrderPhaseCorrection, setFirstOrderPhaseCorrection] = useState(0);
  const [peaks, setPeaks] = useState<
    {
      id: string;
      center: number;
      range: {
        xMin: number;
        xMax: number;
      };
      area?: number;
      couplingFactors?: string;
      multiplicity?: string;
      customMultiplicity?: boolean;
    }[]
  >([]);

  const [peakPickingStatus, updatePeakPickingStatus] = useState<{
    state: PEAK_PICKING_STAGES;
    leftLimit?: number;
    firstCouplingPeak?: number;
    center?: number;
  }>({
    state: PEAK_PICKING_STAGES.INACTIVE
  });

  const { datum } = useContext(Store.Spectrum.Context);

  assert.object(datum);

  const [zoomWindowExtremes, setZoomWindowExtremes] = useState(
    datum.data.x.length
      ? {
          min: datum.data.x[0],
          max: datum.data.x[datum.data.x.length - 1]
        }
      : undefined
  );

  const debouncedZeroOrderPhaseCorrection = useDebounce(
    zeroOrderPhaseCorrection,
    SLIDER_DEBOUNCE_DELAY
  );
  const debouncedFirstOrderPhaseCorrection = useDebounce(
    firstOrderPhaseCorrection,
    SLIDER_DEBOUNCE_DELAY
  );

  const scaleMaxYValue = useMemo(() => {
    const values = datum.data.x.map((_point, index): number => {
      if (!datum.data.im || datum.data.im.length === 0) {
        return datum.data.re[index];
      }

      const real = datum.data.re[index];
      const imag = datum.data.im[index];

      return (
        real * Math.cos(Math.atan((-1 * imag) / real)) -
        imag * Math.sin(Math.atan((-1 * imag) / real))
      );
    });

    // @fixme: Josh- not sure why (b) could ever be NaN
    return values.reduce((a, b) => (Number.isNaN(b) ? a : Math.max(a, b)), 0);
  }, [datum.data]);

  /**
   * filterFactor is the value we use to deterimine how many data points
   * will be drawn on the graph. As the user zooms in further, more points
   * will be drawn on the graph to give a higher resolution when possible.
   * This is calculated as a percentage of the max range of the dataset;
   * when the user's zoom window shows 100% of the dataset, they will see 1/10th
   * of the total points. If they zoom in to show 50% of the range of the dataset,
   * they will see 1/5th of the total points.
   */
  const partialData = useMemo(() => {
    const filterFactor =
      datum.data.x.length && zoomWindowExtremes?.max && zoomWindowExtremes.min
        ? Math.floor(
            (10 * (zoomWindowExtremes.max - zoomWindowExtremes.min)) /
              (datum.data.x[datum.data.x.length - 1] - datum.data.x[0])
          ) || 1
        : 10;

    return [...datum.data.x]
      .map((d, i) => ({
        x: d,
        y: datum.data.im?.length
          ? { real: datum.data.re[i], imag: datum.data.im[i] }
          : datum.data.re[i]
      }))
      .filter((_d, i) => i % filterFactor === 0);
  }, [datum.data, zoomWindowExtremes]);

  const updateDataWindow = useCallback(({ min, max }: { min: number; max: number }) => {
    setZoomWindowExtremes({ min, max });
  }, []);

  const scaleData = useCallback((y) => (y * Y_SCALE_VALUE) / scaleMaxYValue, [scaleMaxYValue]);

  const phaseCorrect = useCallback(
    (y: { real: number; imag: number }, i) => {
      const p0Rad = (debouncedZeroOrderPhaseCorrection * Math.PI) / 180.0;
      const p1Rad = (debouncedFirstOrderPhaseCorrection * Math.PI) / 180.0;

      return (
        y.real * Math.cos(p0Rad + (p1Rad * i) / datum.data.x.length) -
        y.imag * Math.sin(p0Rad + (p1Rad * i) / datum.data.x.length)
      );
    },
    [debouncedZeroOrderPhaseCorrection, debouncedFirstOrderPhaseCorrection, datum.data]
  );

  const correctedData = useMemo(
    () =>
      partialData.map((d, i) => ({
        x: d.x,
        y: scaleData(typeof d.y === 'number' ? d.y : phaseCorrect(d.y, i))
      })),
    [phaseCorrect, partialData, scaleData]
  );

  const chartRef = createRef<{ chart: Chart; container: React.RefObject<HTMLDivElement> }>();

  const storeValue = useMemo((): [DataStore, ActionStore] => {
    const dataStore = {
      zeroOrderPhaseCorrection,
      firstOrderPhaseCorrection,
      peaks,
      correctedData,
      peakPickingStatus,
      chartRef
    };

    const actions = {
      setZeroOrderPhaseCorrection,
      setFirstOrderPhaseCorrection,
      setPeaks,
      updatePeakPickingStatus,
      updateDataWindow
    };

    return [dataStore, actions];
  }, [
    chartRef,
    correctedData,
    firstOrderPhaseCorrection,
    peakPickingStatus,
    peaks,
    updateDataWindow,
    zeroOrderPhaseCorrection
  ]);

  return <SpectrumContext.Provider value={storeValue}>{props.children}</SpectrumContext.Provider>;
};
