/* eslint-disable react/no-danger */
import { Modal } from '@splotch/core-ui';
import { Obj } from '@splotch/core-utils';
import axios from 'axios';
import React, { createRef, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  BASE_SAMPLE_DATA_S3_URL,
  SAMPLE_SPECTRA
} from '../../constants';
import { Spectrum1D } from '../../model';
import { Store } from '../../store';
import {
  ClickableSpan,
  SampleDataContainer,
  SampleDataTable,
  StyledCard
} from './__styled';

export const SampleData = (): React.ReactElement => {
  const prompt = Modal.usePrompt();
  const history = useHistory();
  const { addSpectra } = Store.Spectrum.useAddSpectra();
  const citationRef = createRef<HTMLParagraphElement>();

  const onClickLink = useCallback(
    async (uuid: string) => {
      try {
        const { data } = await axios.get<{ data: Record<string, unknown> }>(
          `${BASE_SAMPLE_DATA_S3_URL}/json/${uuid}.json`
        );
        // @fixme: Josh => pull in __splotch_upload from dataumKey
        const normalized = Spectrum1D.Datum.normalize(Obj.normalize<{ __splotch_upload: unknown }>(data)?.__splotch_upload);

        addSpectra([normalized!]);
        history.push('/spectrum');
      } catch {
        prompt.error({
          title: 'Oops!',
          content: 'Unable to load this sample from the server. Our team is working to make this data available again- please check back shortly!'
        });
      }
    },
    [history, addSpectra]
  );

  return (
    <SampleDataContainer>
      <div className="sample-data-content">
        <h2 className="py-3 m-0 text-center">Need Raw Data?</h2>
        <p className="text-dark">
          First time here? Try it out with any of these unprocessed samples
          taken from raw NMR datasets, courtesy of the{' '}
          <strong>Biological Magnetic Resonance Data Bank</strong>
          <sup className="text-primary">
            <ClickableSpan
              role="button"
               onClick={(): void =>
                 citationRef.current?.scrollIntoView({
                   behavior: 'smooth'
                 })
               }
            >
              [1]
            </ClickableSpan>
          </sup>
          . To find more samples like these, head over to the{' '}
          <a href="https://bmrb.io/metabolomics/">BMRD Website</a> to browse the
          database, download an experimental 1D 1H dataset, unzip the archive,
          and upload to <i>Splotch Labs</i>!
        </p>
        <SampleDataTable rowGap="1rem" gridTemplateColumns="1fr 1fr 1fr 1fr">
          {SAMPLE_SPECTRA.map((item) => (
            <button
              type="button"
              key={item.uuid}
              className="x-pseudo"
              onClick={async (): Promise<void> => onClickLink(item.uuid)}
            >
              <StyledCard key={item.uuid}>
                <Card.Img
                  variant="top"
                  src={`${BASE_SAMPLE_DATA_S3_URL}/svg/${item.uuid}.svg`}
                />
                <Card.Body>
                  <Card.Title><div dangerouslySetInnerHTML={{ __html: item.name }} /></Card.Title>
                </Card.Body>
              </StyledCard>
            </button>
          ))}
        </SampleDataTable>
        <p ref={citationRef} className="m-0 pb-3" style={{ fontSize: '13px' }}>
          <sup>1</sup>{' '}
          <a href="https://academic.oup.com/nar/article-lookup/doi/10.1093/nar/gkm957">
            &quot;BioMagResBank&quot;
          </a>
          , Eldon L. Ulrich; Hideo Akutsu; Jurgen F. Doreleijers; Yoko Harano;
          Yannis E. Ioannidis; Jundong Lin; Miron Livny; Steve Mading; Dimitri
          Maziuk; Zachary Miller; Eiichi Nakatani; Christopher F. Schulte; David
          E. Tolmie; R. Kent Wenger; Hongyang Yao; John L. Markley; Nucleic
          Acids Research 36, D402-D408 (2008) doi: 10.1093/nar/gkm957
        </p>
      </div>
    </SampleDataContainer>
  );
};
