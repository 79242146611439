import _ from 'lodash';
import React from 'react';
import { Button as Button_ } from '../button';

export const Button = React.forwardRef(
  ({ children, ...props }: Button.Props, ref: React.Ref<HTMLButtonElement>): React.ReactElement => (
    <Button_
      {..._.defaults(props, {
        minimum: 'md'
      })}
      ref={ref}
    >
      {children}
    </Button_>
  )
);

Button.displayName = 'ModalButton';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Button {
  export type Size = Button_.Size;
  export type Props = Button_.Props;
}
