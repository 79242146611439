import cx from 'classnames';
import React from 'react';
import type { ReactElement } from 'react';
import { Grid } from '../grid';

export const ButtonBox = ({ children, className, ...props }: ButtonBox.Props): ReactElement => {
  const gap =
    !className || className.search(/gap-((xs|sm|md|lg|xl)-)?\d+px/) === -1
      ? 'gap-8px gap-lg-16px'
      : undefined;

  return (
    <Grid.Symmetric className={cx(className, gap)} {...props}>
      {children}
    </Grid.Symmetric>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ButtonBox {
  export type Props = Grid.Symmetric.Props;
}
