import React from 'react';
import { Footer, Icons, InfoPageWrapper } from '../components';

export const TutorialRoute = (): React.ReactElement => (
  <InfoPageWrapper>
    <div className='info-content flex-grow-1'>
      <h2 className='py-3 font-size-24px'>Tutorial</h2>
      <ol>
        <li>
          <h5>
            <i className='mx-2 text-tertiary fas fa-upload' />
            Upload Your NMR Data
          </h5>
          <p>
            Drag your NMR raw data folder to the drag and drop area, or click the area to select the
            data archive. Note: you will need to upload your data as a zip archive. You&apos;ll then
            be taken the graph page. Currently, Splotch Labs is only able to support raw data
            folders, however we are quickly adding support for additional NMR data formats.
          </p>
          <ul className='mb-3'>
            <li>
              You can always click and drag a region on the graph to zoom in. Hit the &apos;Reset
              Zoom&apos; button at the top right to go back to the full view.
            </li>
            <li>
              To pan the graph once you&apos;re zoomed in, hold down the &#8984; on a Mac or the
              Windows key on Windows, and drag.
            </li>
          </ul>
        </li>
        <li>
          <h5>
            <span className='mx-2'>
              <Icons.PhasingIcon size={24} />
            </span>
            Phase Correction
          </h5>
          <p>
            A key part of processing raw NMR data is applying phase constants to adjust the
            spectrum. Use the <i>Phase Correction</i> tool to adjust the Zero Order (PH0) and First
            Order (PH1) phase constants as needed.
          </p>
        </li>
        <li>
          <h5>
            <span className='mx-2'>
              <Icons.PeakPickingIcon size={24} />
            </span>
            Peak Analysis
          </h5>
          <p>
            Click the <i>Peak Analysis</i> icon to start picking and analyzing peaks and then select
            left and right boundaries for each peak on the graph. You can adjust the Peak Range
            manually by typing values into the input boxes.
          </p>
          <p>
            <i className='text-muted'>
              NOTE: Be sure to perform phase correction before picking peaks, as doing so can change
              the locations where a peak starts and stops.
            </i>
          </p>
          <p>
            To calculate the Coupling Factor (Δppm) on the graph, hit the mouse icon. Then, click on
            the two split peaks to use in the calculation. Repeat this process to calculate multiple
            Coupling Factors for a given peak. You can also manually type Coupling Factor(s) into
            the input box as a comma separated list.
          </p>
          <p>
            <i className='text-muted'>
              NOTE: Be sure to select each peak&apos;s multiplicity from the drop down.
            </i>
          </p>
        </li>
        <li>
          <h5>
            <i className='mx-2 text-tertiary fas fa-sitemap' />
            Supporting Info
          </h5>
          <p>
            This window will generate ACS formatted NMR information based on the information in the
            peak picking table. Enter your solvent, machine frequency, and hit Calculate!
          </p>
          <p>
            <i className='text-muted'>NOTE: Be sure all peaks have a multiplicity selected.</i>
          </p>
        </li>
        <li>
          <h5>
            <i className='mx-2 text-tertiary fas fa-share-square' />
            Export
          </h5>
          <p>Export the graph as a JPEG, PNG, PDF, or SVG.</p>
        </li>
      </ol>
    </div>
    <Footer />
  </InfoPageWrapper>
);
