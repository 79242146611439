import type { Any } from '@splotch/core-utils';
import { xGetFromToIndex } from 'ml-spectra-processing';
import type { Spectrum1D } from '../../spectrum-1d';
import type { Filter } from '../filter';

export namespace FromTo {
  export const id = 'FromTo';
  export const name = 'From/To';

  export const isApplicable = (datum: Spectrum1D.Datum): boolean =>
    !!datum.info.isComplex && !datum.info.isFid;

  export const reduce = (_previousValue: Any, newValue: Any): Filter.ReduceResult => ({
    once: true,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    reduce: newValue
  });

  interface Options {
    from?: number;
    to?: number;
  }

  export const apply = (datum: Spectrum1D.Datum, options: Options = {}): void => {
    if (!isApplicable(datum)) {
      throw new Error('From/To filter not applicable on this data');
    }
    const { x, re, im } = datum.data;
    const { from, to } = options;
    const { fromIndex, toIndex } = xGetFromToIndex([...x], { from, to });

    datum.data.x = x.slice(fromIndex, toIndex);
    datum.data.re = re.slice(fromIndex, toIndex);
    datum.data.im = im?.slice(fromIndex, toIndex);
  };
}
